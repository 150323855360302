import { Organization, Toast } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedInput,
  DesignedSelect,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { FC, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

const organizationTypesOptions = [
  ["elementary_school", "Elementary School"],
  ["middle_school", "Middle School"],
  ["high_school", "High School"],
  ["university", "University"],
  ["community_theatre", "Community Theatre"],
  ["military", "Military"],
  ["religious", "Religious"],
  ["camp", "Camp"],
  ["childrens_theatre", "Childrens Theatre"],
  ["professional", "Professional"],
  ["dance", "Dance"],
  ["other", "Other"],
];

interface OrganizationFormProps {
  saving: boolean;
  handleSubmit: (values: Organization) => void;
  initialValues?: Organization;
  buttonLabel?: string;
  buttonIcon?: string;
}

export const OrganizationForm: FC<OrganizationFormProps> = ({
  saving,
  handleSubmit,
  initialValues,
  buttonIcon,
  buttonLabel,
}) => {
  const [values, _values] = useState<Organization>(
    initialValues
      ? {
          ...initialValues,
        }
      : {
          id: "",
          name: "",
          email: "",
          type: "",
          phone_number: "",
        }
  );

  const [valid] = useState(true);

  return (
    <Box>
      <Row gutter={[36, 18]}>
        <Col xs={24} lg={20}>
          <DesignedInput
            label="Organization Name"
            native={{
              autoFocus: true,
            }}
            value={values.name}
            change={(name) => _values({ ...values, name })}
            bottom={12}
            convert="capitalize"
          />
        </Col>
      </Row>

      <Row gutter={[36, 18]}>
        <Col xs={24} lg={20}>
          <DesignedSelect
            label="Business Type"
            value={values.type}
            change={(type) => _values({ ...values, type })}
            bottom={12}
            empty={true}
            options={organizationTypesOptions}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={20}>
          <DesignedInput
            label="Phone Number"
            value={values.phone_number}
            change={(phone_number) => _values({ ...values, phone_number })}
            bottom={12}
          />
        </Col>
      </Row>
      <DesignedButton
        icon={buttonIcon}
        press={() => {
          if (values.name.length <= 4) {
            Toast.error("Please enter a valid organization name.");
            return;
          }

          if (!values.type) {
            Toast.error("Please select a business type.");
            return;
          }

          handleSubmit(values);
        }}
        disable={!valid}
        label={buttonLabel || "Save"}
        theme="primary"
        size="small"
        loading={saving}
      />
    </Box>
  );
};
