import { DesignedButton } from "@with-nx/simple-ui/atoms";
import { Modal } from "@with-nx/simple-ui/molecules";
import { FC, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { HelpForm } from "./help-form";
import { HelpDetails } from "./types";

interface HelpFormModalProps {
  open: boolean;
  close: () => void;
  submit: () => void;
  initial?: HelpDetails;
  change?: (details: HelpDetails, valid: boolean) => void;
}

export const HelpFormModal: FC<HelpFormModalProps> = ({
  open,
  close,
  submit,
  initial,
  change,
}) => {
  const [valid, _valid] = useState(false);

  return (
    <Modal
      open={open}
      onClose={close}
      title="Help Ticket"
      contentIsFocusable
      footer={
        <DesignedButton
          press={submit}
          disable={
            valid
              ? false
              : ["Please make sure all required fields are filled out."]
          }
          label="Submit"
          theme="primary"
          size="small"
        />
      }
    >
      <Box parse="pl:24 pr:24">
        <HelpForm
          change={(details, valid) => {
            _valid(valid);
            change?.(details, valid);
          }}
          initial={initial}
        />
      </Box>
    </Modal>
  );
};
