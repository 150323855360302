import { Validators } from "@with-nx/hooks-n-helpers";
import { DesignedInput, DesignedSelect } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import CalendarInput from "../calendar-input/calendar-input";
import { HelpDetails } from "./types";

interface HelpFormProps {
  initial?: HelpDetails;
  change?: (details: HelpDetails, valid: boolean) => void;
}

export const HelpForm = (props: HelpFormProps) => {
  const [values, _values] = useState<HelpDetails>(props?.initial || {});
  const [valid, _valid] = useState(true);
  const [focus, _focus] = useState(true);

  useEffect(() => {
    let _valid = valid;

    if (values.name?.length === 0 || values.name === undefined) {
      _valid = false;
    }

    if (values.email?.length === 0 || values.email === undefined) {
      _valid = false;
    }

    if (
      values.phone_number?.length === 0 ||
      values.phone_number === undefined
    ) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  return (
    <>
      <Row gutter={[36, 18]}>
        <Col xs={24}>
          <DesignedInput
            required
            label="Broadway Media Order Number"
            value={values.broadway_media_order_number}
            change={(broadway_media_order_number) =>
              _values({ ...values, broadway_media_order_number })
            }
            native={{
              autoFocus: true,
            }}
            bottom={12}
          />
          <DesignedInput
            required
            label="Name"
            value={values.name}
            change={(name) => _values({ ...values, name })}
            bottom={12}
            convert="capitalize"
          />
          <DesignedInput
            required
            label="Email"
            value={values.email}
            change={(email) => _values({ ...values, email })}
            bottom={12}
            focus={() => _focus(true)}
            blur={() => {
              _valid(Validators.isValidEmailAddress(values.email || ""));
              _focus(false);
            }}
            convert="lowercase"
            error={
              focus
                ? undefined
                : valid
                ? undefined
                : "Please enter a valid email address."
            }
          />
          <DesignedInput
            required
            label="Phone Number"
            value={values.phone_number}
            change={(phone_number) => _values({ ...values, phone_number })}
            bottom={12}
          />
          <DesignedSelect
            required
            label="Subject"
            value={values.subject}
            change={(subject) => _values({ ...values, subject })}
            bottom={12}
            options={[
              ["Other", "Other"],
              ["Software Install", "Software Install"],
              ["Software Malfunction", "Software Malfunction"],
              ["Projector Install", "Projector Install"],
              ["Projector Malfunction", "Projector Malfunction"],
              ["Account Access", "Account Access"],
              ["Billing Inquiry", "Billing Inquiry"],
            ]}
          />
          <CalendarInput
            required
            label="Performance Date"
            value={values.performance_date}
            change={(performance_date) =>
              _values({ ...values, performance_date })
            }
            bottom={12}
          />
          <DesignedInput
            required
            label="Message"
            value={values.message}
            change={(message) => _values({ ...values, message })}
            bottom={12}
            textarea={true}
          />
        </Col>
      </Row>
    </>
  );
};
