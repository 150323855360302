/* eslint-disable @typescript-eslint/no-explicit-any */
import { Toast } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedCard,
  DesignedIcon,
  Rule,
} from "@with-nx/simple-ui/atoms";
import dynamic from "next/dynamic";
import { useState } from "react";
import { Box as SimpleBox } from "simple-effing-primitive-layout";

import DropSearchInput from "../global-search-input/drop-search-input";

interface AddDropsModalProps {
  active?: boolean;
  close?: () => void;
  complete?: (ids: string[]) => void;
}

const Modal = dynamic(() =>
  import("@with-nx/simple-ui/molecules").then((c) => c.Modal)
);

export const AddDropsModal = (props: AddDropsModalProps) => {
  const { active, close, complete } = props;
  const [products, _products] = useState<unknown[]>([]);

  if (!active) {
    return null;
  }

  return (
    <Modal
      open={active || false}
      onClose={close || (() => {})}
      title="Add Drops"
      footer={
        products?.length > 0 ? (
          <SimpleBox parse="d:flex j:flex-end">
            <DesignedButton
              label="Add Drops"
              press={() => {
                if (
                  confirm(
                    "This will override your existing show and reset the editor. Do you confirm?"
                  )
                ) {
                  complete?.(
                    products?.map((i) => String((i as unknown as any).id))
                  );
                }
              }}
            />
          </SimpleBox>
        ) : undefined
      }
    >
      {products?.length > 0 ? (
        <Rule parse="!lt d:block c:?font3">Selected Drops</Rule>
      ) : undefined}
      {products?.map((product) => {
        const _image = (product as unknown as any)?.attachments?.find(
          (i: any) => i?.name === "preview_still"
        )?.blob?.signedUrl;

        return (
          <SimpleBox
            key={(product as unknown as any)?.id}
            parse="d:flex a:center h:54 mb:2"
          >
            <SimpleBox
              parse="br:8 h:32 w:57 c:#1E242D mr:12"
              style={{ minWidth: 57 }}
            >
              <DesignedCard size="16x9" image={_image} mode="cover" />
            </SimpleBox>
            <SimpleBox>
              <Rule parse="!ls d:block mb:3">
                {(product as unknown as any)?.name}
              </Rule>
              <Rule
                parse="!ls d:inline-flex a:center c:?font4"
                press={() => {
                  _products((p) =>
                    p.filter(
                      (p) =>
                        (p as unknown as any)?.id !==
                        (product as unknown as any)?.id
                    )
                  );
                }}
              >
                <DesignedIcon name="multiply" size={12} color="var(--font4)" />{" "}
                Remove
              </Rule>
            </SimpleBox>
          </SimpleBox>
        );
      })}

      <Rule parse="!lt d:block mt:15 c:?font3">Search</Rule>
      <SimpleBox parse="br:10 c:?accent">
        <DropSearchInput
          press={(_p) => {
            if (products.length < 1) {
              if (
                !products.find(
                  (p) =>
                    (p as unknown as any)?.id === (_p as unknown as any)?.id
                )
              ) {
                _products((p) => [...p, _p]);
              }
            } else {
              Toast.error("You can only add 1 drop at a time.");
            }
          }}
        />
      </SimpleBox>
    </Modal>
  );
};

export default AddDropsModal;
